const Services = () => {
  const services = [
    {
      img: "/28162303_7427564.jpg",
      title: "Instant Coverage",
      description:
        "Protection is instantly effective at the point of procedure. Cover is seamlessly activated and verified by SMS and/or email while you’re in a Med Spa, Dental office, or Wellness clinic.",
    },
    {
      img: "/8269356_3792650.jpg",
      title: "Easy Verification",
      description:
        "Complications can be verified by telehealth - even while the patient is still in the chair if necessary - making any claim process quick and efficient.",
    },
    {
      img: "/10440316.png",
      title: "Swift Claims",
      description:
        "Claims can be made for complications arising within 60 days of the post-procedure date. Our team of qualified health professionals can quickly triage and verify a claim meaning an effective remedy can begin almost instantly, saving further physical, emotional, or financial damage for both the patient and provider.",
    },
    {
      img: "/1026.jpg",
      title: "Integrated System",
      description:
        "Fully integrated into existing EMR systems or easily accessible via online digital forms. Each plan is issued directly to the patient via SMS or email at the point of procedure.",
    },
    {
      img: "/833641.jpg",
      title: "Track & Manage",
      description:
        "Providers can instantly track all coverage through a dedicated web portal and receive digital statements, ensuring transparency and ease of management.",
    },
    {
      img: "/1033279_OKBX0M0.jpg",
      title: "Seamless Plan Payment",
      description:
        "Olivarch integrates directly through your EMR software with your POS and deducts plan payments nightly meaning cover is guaranteed and no accounts payable for you. Statements of cover provided are automatically sent to your finance team and available securely for review online 24-7-365.",
    },
  ];

  return (
    <div className="bg-blue-50 py-6">
      <section className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-10">
          <h2 className="text-gold-dark text-4xl font-semibold">
            How it works
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {services.map((service, idx) => (
            <div
              className="bg-white rounded-lg shadow-md text-left overflow-hidden flex flex-col"
              key={idx}
            >
              <img
                src={service.img}
                alt={service.title}
                className="w-full mx-auto mb-4 mt-4 px-4"
              />
              <div className="bg-gray-100 p-6 flex-grow flex flex-col">
                <h3 className="text-xl font-semibold mb-2 text-gold-dark">
                  {service.title}
                </h3>
                <p className="mb-4 flex-grow">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Services;
