import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

const ContactInfo = () => {
  return (
    <div className="py-5 mt-4">
      <div className="container mx-auto">
        <div className="flex flex-col pl-8 ml-8 mr-4 sm:flex-row sm:p-0 flex-wrap gap-8">
          <div className="flex-1 w-full md:w-1/4 lg:w-1/4">
            <div className="flex items-start mb-5">
              <div className="bg-gold-light p-6 rounded-lg mr-3 flex items-center justify-center">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  size="2x"
                  className="text-blue-gray"
                />
              </div>
              <div>
                <h4 className="text-gold-dark text-2xl mb-2">Olivarch Inc.</h4>
                <p className="mb-2">
                  Suite 630
                  <br />
                  6th floor
                  <br /> 1401 Dove St
                  <br /> Newport Beach <br />
                  CA 92660
                  <br />
                  USA
                </p>
              </div>
            </div>
          </div>

          <div className="flex-1 w-full md:w-1/4 lg:w-1/4">
            <div className="flex items-start mb-5">
              <div className="bg-gold-light p-6 rounded-lg mr-3 flex items-center justify-center">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="2x"
                  className="text-blue-gray"
                />
              </div>
              <div>
                <h4 className="text-gold-dark text-2xl mb-2">Mail Us</h4>
                <p className="mb-2">
                  <a
                    href="mailto:contactus@olivarch.com"
                    className="font-semi-bold"
                  >
                    contactus@olivarch.com
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="flex-1 w-full md:w-1/4 lg:w-1/4">
            <div className="flex items-start mb-5">
              <div className="bg-gold-light p-6 rounded-lg mr-3 flex items-center justify-center">
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  size="2x"
                  className="text-blue-gray transform scale-x-[-1]"
                />
              </div>
              <div>
                <h4 className="text-gold-dark text-2xl mb-2">Call Us</h4>
                <p className="mb-2">
                  <a href="tel:+1 (949) 877-7782" className="font-semi-bold">
                    +1 (949) 877-7782
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="flex-1 w-full md:w-1/2 lg:w-1/4">
            <div className="flex items-start mb-2">
              <div className="bg-gold-light p-6 rounded-lg mr-3 flex items-center justify-center">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  size="2x"
                  className="text-blue-gray"
                />
              </div>
              <div>
                <h4 className="text-gold-dark text-2xl mb-2">Olivarch Ltd.</h4>
                <p className="mb-2">
                  Suite F38-39
                  <br /> Cheadle Place
                  <br /> Stockport Rd
                  <br />
                  Cheadle SK8 2GL
                  <br /> United Kingdom
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
