import logo from "../assets/logo.png";
import TopBanner from "./TopBanner";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  return (
    <>
      <TopBanner />
      <header className="bg-white shadow-md">
        <div className="container mx-auto flex flex-wrap items-end p-4">
          <div className="flex px-4 mb-2 order-1">
            <img src={logo} alt="Logo" className="w-20 h-10" />
          </div>
          <div className="flex self-stretch px-4 bg-gray-100 rounded-md py-2 grow order-2 md:order-3">
            <nav className="space-x-10 flex items-center">
              <Link
                to="about"
                smooth={true}
                duration={500}
                className="text-gray-500 hover:text-blue-500 font-semibold"
                activeClass="text-blue-800"
                spy={true}
              >
                About
              </Link>
              <Link
                to="contact"
                smooth={true}
                duration={500}
                className="text-gray-500 hover:text-blue-500 font-semibold"
                activeClass="text-blue-800"
                spy={true}
              >
                Contact
              </Link>
            </nav>
          </div>
          <div className="flex items-center ml-8 pl-8 mb-1 mt-2 pr-8 order-3 md:flex">
            <div className="bg-gold-light rounded-full p-3 w-12 items-center">
              <FontAwesomeIcon
                icon={faPhoneAlt}
                size="1x"
                className="text-blue-gray transform scale-x-[-1] ml-1"
              />
            </div>

            <div className="ml-3">
              <a
                href="tel:+1(949)877-7782"
                className="text-blue-gray font-semibold"
              >
                +1 (949) 877-7782
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
