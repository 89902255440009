const Testimonials = () => {
 
  return (
    <section className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="text-center mb-10">
       <h1 className="text-5xl text-blue-gray font-bold mt-2 mb-4">
        Ready to offer peace of mind?
        </h1>
        <p className="text-xl text-blue-gray  mx-auto">
        Get started with Olivarch today. Whether you're a patient or a provider, our procedure protection<br/> extended service warranty cover is designed to protect you when you need it most.
        </p>
      </div>

      

    </section>
  );
};


export default Testimonials;
