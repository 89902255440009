import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
  faShieldAlt,
  faBullseye,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";

const Features = () => {
  const features = [
    {
      icon: faAward,
      title: "Fast: Immediate Protection with No Extra Admin Work",
      description:
        "Olivarch activates within 48 hours, automatically enrolling patients through EMR integration. Adverse events are reported via a simple link in an email or text message, with real-time verification and fast claims processing.",

    },
    {
      icon: faBullseye,
      title: "Affordable: Clear, Transparent Pricing",
      description: `Flat-fee pricing of $15, $25, or $60 per procedure. No hidden fees, just simple, predictable costs that fit seamlessly into your practice’s financial structure.`,

    },
    {
      icon: faHeadset,
      title: "Comprehensive: Coverage for a Wide Range of Procedures",
      description:
        "Olivarch covers FDA-approved and pre-agreed “off-label” procedures, addressing 99% of adverse events. Protect your patients with reliable coverage for a wide range of treatments and surgeries.",

    },
    {
      icon: faShieldAlt,
      title: "Effective: Protect Your Practice and Patients",
      description:
        "Olivarch reduces refunds, chargebacks, and lawsuits. Retain 35-50% of patients after complications, with claims approved in under 10 days, protecting your reputation and revenue.",

    },
  ];

  return (
    <div className="bg-blue-50 py-12">
      <section className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-10">
          <h2 className="text-4xl font-semibold text-gold-dark">
            Why Choose Olivarch?
          </h2>
          <p className="text-lg text-gray-800 mt-2">
            Adverse events often come with hidden costs—refunds, chargebacks,
            and the loss of patient trust. With Olivarch, you not only protect
            your patients, but also safeguard your revenue and reputation. Our
            Fast, Affordable, Comprehensive, and Effective approach ensures that
            your practice can focus on delivering exceptional care without
            worrying about the financial or operational fallout of
            complications.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-8">
          {features.map((feature, idx) => (
            <div
              className="bg-white rounded-lg shadow-md flex flex-col p-6"
              key={idx}
            >
              <div className="flex items-center mb-4">
                <span className="text-5xl text-gold-light mr-4">
                  <FontAwesomeIcon icon={feature.icon} />
                </span>
                <h3 className="text-2xl font-semibold text-gold-dark">
                  {feature.title}
                </h3>
              </div>
              <p className="text-blue-gray mb-4">{feature.description}</p>

              <div>
                

              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Features;
