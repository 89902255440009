import React from "react";
import logo from "../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import ContactInfo from "./ContactInfo";

import instagramImage1 from "../assets/instagram-footer-1.jpg";
import instagramImage2 from "../assets/instagram-footer-2.jpg";
import instagramImage3 from "../assets/instagram-footer-3.jpg";
import instagramImage4 from "../assets/instagram-footer-4.jpg";
import instagramImage5 from "../assets/instagram-footer-5.jpg";
import instagramImage6 from "../assets/instagram-footer-6.jpg";

const Footer: React.FC = () => {
  return (
    <footer id="contact" className="bg-gray-900 text-white py-5 mt-8">
      <div className="container mx-auto">
        <div className="flex flex-wrap gap-8 p-4">
          <div className="flex-1 w-full md:w-1/4 lg:w-1/4">
            <div className="mb-5">
              <h3 className="text-3xl mb-4 flex items-center">
                <img src={logo} alt="Instagram 1" className="w-20 h-10" />
              </h3>
              <p className="mb-4">
                Extended Service Protection for Med Spas, Dentists & Wellness
                clinics.
              </p>
              <div className="flex space-x-4">
                <a
                  className="bg-gold-light pt-2 pb-2 pl-4 pr-4 rounded-full text-blue-gray text-xl hover:bg-gray-200"
                  href="#"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
               
                <a
                  className="bg-gold-light pt-2 pb-2 pl-3 pr-3 rounded-full text-blue-gray text-xl hover:bg-gray-200"
                  href="#"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  className="bg-gold-light pt-2 pb-2 pl-3 pr-3 rounded-full text-blue-gray text-xl hover:bg-gray-200"
                  href="#"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </div>
          </div>

          <div className="flex-1 w-full md:w-1/4 lg:w-1/4">
            <div className="mb-5">
              <h4 className="text-gold-light text-2xl mb-4">Useful Links</h4>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="flex items-center">
                    <i className="fas fa-angle-right mr-2"></i> About
                  </a>
                </li>
                <li>
                  <a href="#" className="flex items-center">
                    <i className="fas fa-angle-right mr-2"></i> Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* <div className="flex-1 w-full md:w-1/4 lg:w-1/4">
                        <div className="mb-5">
                            <h4 className="text-gold-light text-2xl mb-4">Instagram</h4>
                            <div className="grid grid-cols-3 gap-2">
                                <div className="relative">
                                    <img src={instagramImage1} alt="Instagram 1" className="w-full h-full object-cover rounded" />
                                    <a href={instagramImage1} className="absolute inset-0 flex items-center justify-center text-white text-xl"><i className="fas fa-link"></i></a>
                                </div>
                                <div className="relative">
                                    <img src={instagramImage2} alt="Instagram 2" className="w-full h-full object-cover rounded" />
                                    <a href={instagramImage2} className="absolute inset-0 flex items-center justify-center text-white text-xl"><i className="fas fa-link"></i></a>
                                </div>
                                <div className="relative">
                                    <img src={instagramImage3} alt="Instagram 3" className="w-full h-full object-cover rounded" />
                                    <a href={instagramImage3} className="absolute inset-0 flex items-center justify-center text-white text-xl"><i className="fas fa-link"></i></a>
                                </div>
                                <div className="relative">
                                    <img src={instagramImage4} alt="Instagram 4" className="w-full h-full object-cover rounded" />
                                    <a href={instagramImage4} className="absolute inset-0 flex items-center justify-center text-white text-xl"><i className="fas fa-link"></i></a>
                                </div>
                                <div className="relative">
                                    <img src={instagramImage5} alt="Instagram 5" className="w-full h-full object-cover rounded" />
                                    <a href={instagramImage5} className="absolute inset-0 flex items-center justify-center text-white text-xl"><i className="fas fa-link"></i></a>
                                </div>
                                <div className="relative">
                                    <img src={instagramImage6} alt="Instagram 6" className="w-full h-full object-cover rounded" />
                                    <a href={instagramImage6} className="absolute inset-0 flex items-center justify-center text-white text-xl"><i className="fas fa-link"></i></a>
                                </div>
                            </div>
                        </div>
                    </div> */}

          <div className="flex-1 w-full md:w-1/4 lg:w-1/4 mr-3">
            <div className="mb-5">
              <h4 className="text-gold-light text-2xl mb-4">Newsletter</h4>
              <p className="mb-3">
                {" "}
                Extended Service Protection for Med Spas, Dentists & Wellness
                clinics.
              </p>
              <div className="relative">
                <input
                  className="form-control rounded-full w-full py-2 px-4"
                  type="email"
                  placeholder="Enter your email"
                />
                <button
                  type="button"
                  className="absolute top-0 right-0 py-2 px-4 bg-primary text-white rounded-full"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flow-root">
        <div className="float-right p-4">
          Images provided by&nbsp;<a href="https://www.freepik.com">Freepik</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
