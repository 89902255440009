import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const TopBanner: React.FC = () => {
  return (
    <div className="bg-gray-100 py-2">
      <div className="container mx-auto flex flex-wrap items-end justify-between px-4 lg:px-8">
        <div className="flex flex-wrap items-center text-center mb-1 px-2">
          <div className="flex items-center">
            <a
              href="mailto:contactus@olivarch.com"
              className="text-gray-600 text-sm flex items-center"
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-[#36454f] mr-2 mt-0.5"
              />{" "}
              contactus@olivarch.com
            </a>
          </div>
        </div>
        <div className="flex items-end justify-center lg:justify-end">
          <div className="flex items-center border-r border-primary pr-3">
            <a className="text-[#36454f] text-xl mr-3" href="#">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a className="text-[#36454f] text-xl mr-3" href="#">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              className="text-[#36454f] text-xl"
              href="https://www.linkedin.com/company/olivarch/"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
