import patient from "../assets/patient.png";
import providers from "../assets/providers.jpg";
import owners from "../assets/owners.png";

const Blog = () => {
  const blogItems = [
    {
      img: "11663539_20944489.jpg",
      title: "Patients",
      description:
        "Instant peace of mind knowing that if an unexpected outcome happens after their procedure, then they have instant remedy without embarrassment or financial stress.",
    },
    {
      img: "/8349510.png",
      title: "Providers",
      description:
        "Enhanced patient satisfaction and loyalty by offering reliable protection that starts from the moment the procedure begins, ensuring a smooth and stress-free experience.",
    },
    {
      img: "/owners.png",
      title: "Business Owners",
      description:
        "Improve client retention and streamline your practice with Olivarch's easy-to-use, integrated platform.",
    },
  ];

  return (
    <section className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="text-center mb-10">
        <h2 className="text-gold-dark text-5xl font-semibold">Who benefits?</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {blogItems.map((item, idx) => (
          <div
            className="bg-white rounded-lg shadow-md text-left overflow-hidden flex flex-col"
            key={idx}
          >
            <img
              src={item.img}
              alt={item.title}
              className="w-full mx-auto mb-6 px-4"
            />
            <div className="bg-gray-100 p-6 flex-grow">
              <h3 className="text-gold-dark text-2xl font-semibold mb-2">
                {item.title}
              </h3>
              <p className="mb-4">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Blog;
