const Common = () => {
  return (
    <section className=" py-12">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex items-center">
        <div className="w-full lg:w-1/2">
          <h1 className="text-5xl font-bold text-gray-800 mt-2 mb-6">
            Complications are a bad luck lottery. We help everyone win.
          </h1>
          <p className="text-xl text-gray-800 mt-2">
            Olivarch provides affordable, comprehensive protection for patients and providers during minimally invasive procedures. When complications arise, we cover you instantly, allowing you to focus on recovery without stress or worry.
          </p>
        </div>

        <div className="hidden lg:flex lg:w-1/2 justify-center">
          <img
            src="/about-1.png"
            alt="FAQ Illustration"
            className="max-w-full h-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default Common;
