import Blog from "./Blog";
import Common from "./Common";
import Features from "./Features";
import AboutFeatureSection from "./AboutFeatureSection";
import Hero from "./Hero";
import Services from "./Services";
import OurTeam from "./Team";
import Testimonials from "./Testimonial";
import ContactInfo from "./ContactInfo";

export default function Home() {
    return <>
        <Hero />
        <Common/>
        <Features />
        <AboutFeatureSection/>
        <Services />
       <Blog />
        <OurTeam />
        <Testimonials />
        <ContactInfo/>
    </>
}