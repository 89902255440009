const Hero = () => {
  return (
    <section className="bg-gold-light text-white py-20">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-8">
        <div className="text-center md:text-left">
          <h1 className="mt-2 text-3xl font-semibold text-blue-gray">
            Welcome to Olivarch
          </h1>
          <h1 className="mt-2 text-4xl font-bold text-blue-gray">
            Extended Service Protection for Med Spas, Dentists & Wellness
            clinics.
          </h1>
        </div>
        <div className="mt-8 md:mt-0">
          <img
            src="/45160740_9082599.png"
            alt="Insurance Illustration"
            className="max-w-xs mx-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
