const AboutFeatureSection = () => {
  return (
    <section id="about" className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-stretch my-8">
        <div className="p-8 bg-white">
          <h2 className="text-gold-dark text-5xl font-semibold mb-2">
            About Our Company
          </h2>

          <p className="text-xl text-blue-gray mt-4 mb-4">
            Our leadership team brings together decades of experience across
            aesthetics, healthcare, technology, and compliance. We’re passionate
            about building real, meaningful solutions for the aesthetics
            industry, and we’re here to ensure your success every step of the
            way.
          </p>
        </div>

        <div className="space-y-8 bg-white">
          <div className="bg-white p-8 rounded-lg">
            <img
              src="/4025172.jpg"
              alt="Illustration"
              className="img-fluid rounded w-100"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutFeatureSection;
