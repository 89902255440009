const OurTeam = () => {
  const teamMembers = [
    {
      img: "/team-1.jpg",
      name: "Lee Evans",
      profession: "Chief Executive Officer & Founder",
      Title: "Board Director ",
    },
    {
      img: "/team-3.jpg",
      name: "Tim Derham",
      profession: "Chief Insurance Officer",
      Title: "Board Director ",
    },
    {
      img: "/team-7.jpg",
      name: "Nicole Strothman",
      profession: "Chief Legal Officer",
      Title: "Board Director ",
    },
    {
      img: "/team-8.jpg",
      name: "Dr Kian Karimi",
      profession: "Chief Medical Officer",
      Title: "Board Director ",
    },
    {
      img: "/team-4.jpg",
      name: "Ian Foster",
      profession: "Chief Technology Officer",
    },
    {
      img: "/team-6.jpg",
      name: "Mariela Martinez",
      profession: "Head of Client Experience & Onboarding",
    },
    {
      img: "/team-2.png",
      name: "Stuart John",
      profession: "Chief Operations & Product Officer",
    },
  ];

  return (
    <section className="container mx-auto px-4 sm:px-6 lg:px-4 py-4">
      <div className="text-center mb-10">
        <h2 className="text-gold-dark text-4xl font-semibold">Our Team</h2>
      </div>

      {/* First Row with 4 Columns */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
        {teamMembers.slice(0, 4).map((member, idx) => (
          <div
            className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-full justify-between"
            key={idx}
          >
            <img
              src={member.img}
              alt={member.name}
              className="w-24 mx-auto mt-4 pb-2"
            />
            <div className="bg-gold-light text-blue-gray p-4 flex-grow flex flex-col items-center justify-center">
              <h3 className="text-lg font-semibold">{member.name}</h3>
              <p className="text-md">{member.profession}</p>
              {member.Title && <p className="text-md">{member.Title}</p>}
            </div>
          </div>
        ))}
      </div>

      {/* Second Row with 3 Columns Centered */}
      <div className="flex justify-center mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full max-w-4xl">
          {teamMembers.slice(4).map((member, idx) => (
            <div
              className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-full justify-between"
              key={idx + 4} // Adjust index to avoid key duplication
            >
              <img
                src={member.img}
                alt={member.name}
                className="w-24 mx-auto mt-4 pb-2"
              />
              <div className="bg-gold-light text-blue-gray p-4 flex-grow flex flex-col items-center justify-center">
                <h3 className="text-lg font-semibold">{member.name}</h3>
                <p className="text-md text-center">{member.profession}</p>
                {member.Title && <p className="text-md text-center">{member.Title}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
